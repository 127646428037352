import { createSlice } from "@reduxjs/toolkit";
import { userInitState } from "../schema";

export const userSlice = createSlice({
    name: 'USER',
    initialState: userInitState,
    reducers: {
        setUser: (state, { payload }) => { 
            state.userIsFetching = false;
            state.data = payload.data;
            state.userSuccess = payload.success;
         },
        userIsFetching: (state) => { state.userIsFetching = true },
        userCleanStatus: (state) => {
            state.userIsFetching = false;
            state.userSuccess = false;
        }
    },
});

export const {
    setUser, userIsFetching, userCleanStatus
} = userSlice.actions;